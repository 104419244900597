import React from 'react';
import i18n from "../i18n";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";

export default class ErrorPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      message: (props.location.state && props.location.state.message) || i18n.t("internal_error"),
    };

    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "error"});
    hitEvent(hits.PHOTO_SELECT);

    this.props.history.push(routes.UPLOAD, {file});
  }

  render() {
    return <main className="error-page">
      <div className="container">
        <div className="error-content">
          <svg viewBox="0 0 792 471">
            <g fill="none" fillRule="evenodd">
              <g fill="#868686" fillRule="nonzero" stroke="#F6F6F6">
                <g>
                  <path strokeWidth="5" d="M519.5 113.5v355h-247V187.551l74.051-74.051H519.5zm-7 7h-161v72h-72v269h233v-341zm-168 4.949L284.449 185.5H344.5v-60.051z" transform="translate(-225 -636) translate(225 636)"/>
                  <path strokeWidth="5" d="M364.97 257.077l4.948 4.949L352.949 279l16.969 16.974-4.949 4.95L348 283.947l-16.97 16.975-4.948-4.949L343.051 279l-16.969-16.974 4.949-4.95L348 274.053l16.97-16.975zM454.97 257.077l4.948 4.949L442.949 279l16.969 16.974-4.949 4.95L438 283.947l-16.97 16.975-4.948-4.949L433.051 279l-16.969-16.974 4.949-4.95L438 274.053l16.97-16.975z" transform="translate(-225 -636) translate(225 636)"/>
                  <path strokeWidth="3" d="M721.993 101.148l2.035 3.525-12.247 7.072 7.07 12.248-3.525 2.035-7.07-12.249-12.249 7.073-2.035-3.525 12.247-7.072-7.07-12.248 3.525-2.035 7.07 12.249 12.249-7.073zM629.085 424.042l2.616 3.118-10.833 9.09 9.09 10.835-3.118 2.616-9.09-10.835-10.835 9.092-2.616-3.118 10.833-9.09-9.09-10.835 3.118-2.616 9.09 10.835 10.835-9.092z" transform="translate(-225 -636) translate(225 636)"/>
                  <path strokeWidth="1.5" d="M788.589 260.482v2.035h-7.071v7.072h-2.036v-7.072h-7.07l-.001-2.034h7.071v-7.072h2.036v7.072h7.07zM695.05 343.423l.527 1.966-6.83 1.83 1.83 6.831-1.966.527-1.83-6.831-6.83 1.83-.528-1.965 6.83-1.83-1.83-6.831 1.966-.527 1.83 6.831 6.83-1.83zM551.05 48.423l.527 1.966-6.83 1.83 1.83 6.831-1.966.527-1.83-6.831-6.83 1.83-.528-1.965 6.83-1.83-1.83-6.831 1.966-.527 1.83 6.831 6.83-1.83zM18.05 353.423l.527 1.966-6.83 1.83 1.83 6.831-1.966.527-1.83-6.831-6.83 1.83-.528-1.965 6.83-1.83-1.83-6.831 1.966-.527 1.83 6.831 6.83-1.83zM66.542 114.027l1.168 1.667-5.792 4.056 4.055 5.793-1.667 1.167-4.056-5.793-5.792 4.056-1.168-1.667 5.792-4.056-4.055-5.793 1.667-1.167 4.056 5.793 5.792-4.056zM363.5 1.06l1.44 1.44-5 5 5 5-1.44 1.44-5-5.001-5 5-1.44-1.439 5-5-5-5 1.44-1.44 5 5.001 5-5zM653.05 214.423l.527 1.966-6.83 1.83 1.83 6.831-1.966.527-1.83-6.831-6.83 1.83-.528-1.965 6.83-1.83-1.83-6.831 1.966-.527 1.83 6.831 6.83-1.83z" transform="translate(-225 -636) translate(225 636)"/>
                  <path strokeWidth="3" d="M211.178 105.965v4.07h-14.142l-.001 14.143h-4.07v-14.143h-14.143v-4.07h14.142l.001-14.143h4.07v14.143h14.143zM137.1 265.847l1.054 3.931-13.66 3.661 3.659 13.661-3.932 1.053-3.66-13.661-13.66 3.661-1.055-3.931 13.66-3.661-3.659-13.661 3.932-1.053 3.66 13.661 13.66-3.661zM185.085 410.042l2.616 3.118-10.833 9.09 9.09 10.835-3.118 2.616-9.09-10.835-10.835 9.092-2.616-3.118 10.833-9.09-9.09-10.835 3.118-2.616 9.09 10.835 10.835-9.092z" transform="translate(-225 -636) translate(225 636)"/>
                  <path strokeWidth="5" d="M396 336.384c16.247 0 32.494 6.19 44.9 18.566h0l3.807 3.808-4.95 4.949-3.809-3.81c-11.04-11.01-25.494-16.515-39.948-16.515s-28.909 5.505-39.95 16.517h0l-3.808 3.808-4.949-4.95 3.806-3.805c12.407-12.379 28.654-18.568 44.901-18.568z" transform="translate(-225 -636) translate(225 636)"/>
                </g>
              </g>
            </g>
          </svg>
          <h3>{i18n.t("error__error")}</h3>
          <p>{this.state.message}</p>
        </div>

        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("try_another_photo")} />

      </div>
    </main>;
  }
}

ErrorPage.contextType = AppContext;
