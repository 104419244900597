import promiseRetry from "promise-retry";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

const defaultRetriesConfig = {
  retries: 3,
  minTimeout: 1000,
  maxTimeout: 3000
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = data.error_message;
    this.response = data;
  }
}

export class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}


function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

export function photolabSign(data) {
  const params = {data};
  params.rv = 1;
  params.cv = 1;
  params.cn = window.appConfig.clientName;

  return window.axios.post(window.appConfig.paths.apiSign + "/photolab/sign", params)
    .then(checkApiResponse);
}

export function createFile(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  return window.axios.post(window.appConfig.paths.apiUpload + "/files/create", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  }).then(checkApiResponse);
}


export function createTask(type, params) {
  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.api + "/tasks/create", {type, params}).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function fetchTask(taskId) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/tasks/" + taskId).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

// --

export function waitTask(taskId, timeout = 1000, interval = 1000) {
  function _call(resolve, reject) {
    fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}