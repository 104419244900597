import React from "react";

export default class DumbImageView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.image = new Image();
    this.image.onload = () => {
      this.props.onImageLoaded && this.props.onImageLoaded();
      this.setState({isLoading: false});
    };

    this.load = this.load.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.image) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.image.onload = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.load();
    }
  }

  load() {
    this.setState({
      isLoading: true,
    }, () => {
      this.image.src = this.props.image;
    });
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }

  render() {
    const isImageDisplayed = this.props.image && !this.state.isLoading;
    const styles = {};

    if (isImageDisplayed) {
      styles.backgroundImage = `url(${this.props.image})`;
    }

    return <div
      className={this.props.className || ""}
      onClick={this.handleClick}
    >
      <img src={this.props.image} alt='' />
    </div>;
  }
}
